<template>
  <!-- Request -->
  <div class="card card-flush mt-4">
    <div class="card-body">
      <h3>REQUEST</h3>
      <div class="mt-4">
        <!-- URL -->
        <div class="input-group mb-3">
          <span class="input-group-text">{{
            method + " " + $t("ApiManual.PostAddress")
          }}</span>
          <input
            type="text"
            class="form-control"
            :disabled="true"
            :value="url"
          />
        </div>
        <!-- Header -->
        <label for="basic-url" class="form-label">Request Header</label>
        <div class="input-group mb-3">
          <span class="input-group-text">AGENTCODE</span>
          <input
            type="text"
            class="form-control"
            :disabled="true"
            :value="$store.state.AuthModule.user.agent"
          />
        </div>
        <div class="input-group mb-3">
          <span class="input-group-text">AGENTID</span>
          <input
            type="text"
            class="form-control"
            :disabled="true"
            :value="$store.state.AuthModule.user.id"
          />
        </div>
        <!-- Body -->
        <label for="basic-url" class="form-label" v-if="method === 'POST'"
          >Request Body (application/json)</label
        >
        <!-- Launch Request -->
        <div v-if="page === 'LAUNCH'">
          <!-- Username -->
          <div class="row">
            <div class="col-xxl-6">
              <div class="input-group mb-3">
                <span class="input-group-text">username</span>
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="launch.username"
                  placeholder="usertest"
                />
              </div>
            </div>
            <div class="col-xxl-6">
              <label class="text-danger mt-3">
                {{ $t("ApiManual.UsernameNotice") }}
              </label>
            </div>
          </div>
          <!-- Username -->
          <div class="row">
            <div class="col-xxl-6">
              <div class="input-group mb-3">
                <span class="input-group-text">name</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="launch.name"
                  placeholder="name"
                  required
                />
              </div>
            </div>
            <div class="col-xxl-6">
              <label class="text-danger mt-3">
                {{ $t("ApiManual.NameNotice") }}
              </label>
            </div>
          </div>
          <!-- ID -->
          <div class="row">
            <div class="col-xxl-6">
              <div class="input-group mb-3">
                <span class="input-group-text">id</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="launch.id"
                  placeholder="1001"
                  required
                />
              </div>
            </div>
            <div class="col-xxl-6">
              <label class="text-danger mt-3">
                {{ $t("ApiManual.IDNotice") }}
              </label>
            </div>
          </div>
          <!-- Game -->
          <div class="row">
            <div class="col-xxl-6">
              <div class="input-group mb-3">
                <span class="input-group-text">lobby</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="launch.lobby"
                  placeholder="EVO"
                  required
                />
              </div>
            </div>
            <div class="col-xxl-6">
              <label class="text-danger mt-3">
                {{ $t("ApiManual.GameNotice") }}
              </label>
            </div>
          </div>
          <!-- Game ID-->
          <div class="row" v-if="game_type !== 'LIVE'">
            <div class="col-xxl-6">
              <div class="input-group mb-3">
                <span class="input-group-text">game_id</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="launch.game_id"
                  placeholder="1"
                  required
                />
              </div>
            </div>
            <div class="col-xxl-6">
              <label class="text-danger mt-3">
                {{ $t("ApiManual.GameIDNotice") }}
              </label>
            </div>
          </div>
        </div>
        <!-- Deposit Request -->
        <div v-if="page === 'DEPOSIT'">
          <!-- Snow Member ID -->
          <div class="row">
            <div class="col-xxl-6">
              <div class="input-group mb-3">
                <span class="input-group-text">member_id</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="launch.member_id"
                  placeholder="9fd4acc3-fa2c-4fc4-a530-fa517e8cdd6d"
                  required
                />
              </div>
            </div>
            <div class="col-xxl-6">
              <label class="text-danger mt-3">
                {{ $t("ApiManual.SnowMemberIDNotice") }}
              </label>
            </div>
          </div>
          <!-- Game -->
          <div class="row" v-if="game_type === 'SLOT'">
            <div class="col-xxl-6">
              <div class="input-group mb-3">
                <span class="input-group-text">lobby</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="launch.game"
                  placeholder="TM_CQ9"
                  required
                />
              </div>
            </div>
            <div class="col-xxl-6">
              <label class="text-danger mt-3">
                {{ $t("ApiManual.GameNotice") }}
              </label>
            </div>
          </div>
          <!-- Snow Amount -->
          <div class="row">
            <div class="col-xxl-6">
              <div class="input-group mb-3">
                <span class="input-group-text">amount</span>
                <input
                  type="number"
                  class="form-control"
                  v-model="launch.amount"
                  placeholder="1000"
                  required
                />
              </div>
            </div>
            <div class="col-xxl-6">
              <label class="text-danger mt-3">
                {{ $t("ApiManual.AmountNotice") }}
              </label>
            </div>
          </div>
        </div>
        <!-- Submit -->
        <div class="mt-4 d-flex justify-content-end">
          <button class="btn btn-primary" @click="sendRequest()">
            <i class="fas fa-paper-plane fs-4 me-2"></i>
            {{ $t("ApiManual.Submit") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Response -->
  <div class="card card-flush mt-4">
    <div class="card-body">
      <h3>RESPONSE</h3>
      <div class="mt-4">
        {{ responseStatus }}
        <CodeHighlighter lang="json" :key="new Date().getTime()">
          {{ response }}
        </CodeHighlighter>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import ApiService from "@/core/services/ApiService";
import moment from "moment";

export default defineComponent({
  name: "ApiTester",
  components: {
    CodeHighlighter,
  },
  props: {
    url: { type: String, required: true },
    method: { type: String, required: true },
    game_type: { type: String, required: true },
    page: { type: String, required: true },
  },
  setup(props) {
    let launch = reactive({
      username: "",
      name: "",
      id: "",
      game: "",
      game_id: "",
    });

    if (props.game_type === "LIVE") {
      launch = reactive({
        username: "",
        name: "",
        id: "",
        lobby: "",
      });
    }

    if (props.page === "DEPOSIT") {
      launch = reactive({
        member_id: "",
        amount: "",
        game: "",
      });
    } else if (props.page === "BALANCE" || props.page === "WITHDRAWAL") {
      launch = reactive({
        member_id: "",
        game: "",
      });
    } else if (props.page === "BETHISTORY") {
      launch = reactive({
        start: moment().subtract(15, "minutes").format("YYYY-MM-DD HH:mm:ss"),
        end: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    } else if (props.page === "GAMELIST") {
      launch = reactive({
        gamelist: "",
      });
    }
    const responseStatus = ref("");
    const response = ref({});

    const sendRequest = async () => {
      let apiURL = props.url;

      await ApiService.post("/agent/api-test", {
        apiURL,
        request: launch,
        method: props.method,
      })
        .then((res) => {
          responseStatus.value = res.status;
          response.value = res.data.data;
        })
        .catch((e) => {
          responseStatus.value = e.response.status;
          response.value = e.response.data.data;
        });
    };
    return {
      launch,
      sendRequest,
      response,
      responseStatus,
    };
  },
});
</script>
