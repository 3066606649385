
import { defineComponent, onMounted } from "vue";
import { useI18n } from "vue-i18n/index";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiHeader from "@/components/manual/ApiHeader.vue";
import SampleCode from "@/components/manual/SampleCode.vue";
import ApiTester from "@/components/manual/ApiTester.vue";

export default defineComponent({
  name: "live-launch",
  components: {
    ApiHeader,
    ApiTester,
    SampleCode,
  },
  setup() {
    // vue variables
    const { t } = useI18n();
    // page variables
    const apiURL = "https://api.defensilx918.com/api/launch";
    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(t("ApiManual.Auth"), [
        t("ApiManual.RealtimeCasino"),
      ]);
    });

    const phpCode = `<?php
    
$agent = ""; // 에이전트 코드
$agentId = ""; //에이전트 아이디

function launch($username, $name, $id, $game) {

  if (isset($username) && isset($name) && isset($id)  && isset($game)) {
    $payload = array(
      'username' => $username,
      'name' => $name,
      'id' => $id,
      'lobby' => $game,
    );

    try {

      $ch = curl_init('https://api.defensilx918.com/api/launch');
      curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($payload));
      curl_setopt($ch, CURLOPT_HTTPHEADER, array(
        'Accept: application/json',
        'Content-Type: application/json',
        'AGENTCODE:' . $agent, // 에이전트 코드
        'AGENTID:' . $agentId) // 에이전트 아이디
      );
      curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);

      $response = json_decode(curl_exec($ch), true); // CURL 발생
      curl_close($ch);

      if($response['launch_url']) { // 성공
        echo $response['launch_url'];
      } else { // 오류
        echo "LAUNCH_FAILED";
      }
    } catch(Exception $e) {
      echo "서바 오류 발생";
    }

  } else {
    echo "잘못된 데이타."
  }
}
?>`;
    const aspCode = `<!--#include virtual="/jsonObject.class.asp" -->

<%

AGENTCODE = "" ' 에이전트 코드
AGENTID = "" ' 에이전트 아이디

gameurl = "https://api.defensilx918.com/api/launch"

Function LaunchURL(url, game, username, userid)

	If game = "" Or username = "" Or userid = "" Then
		Response.Status = "403 Forbidden"
		Response.write = "잘못된 데이타."
		Response.end
	End If

	'Set JSON Data
	set JSONData = New JSONobject
	JSONData.Add "username", username
	JSONData.Add "name", username
	JSONData.Add "lobby", lobby
	JSONData.Add "id", userid
	
	set JSON = New JSONobject

	JSON.Add "command", command
	JSON.Add "data", JSONData.parse(JSONData.serialize())
	JSON.Add "request_timestamp", requestTime
	JSON.Add "hash", requestHash

	'declare a variable
	Dim objXmlHttp

	Set objXmlHttp = Server.CreateObject("Microsoft.XMLHTTP")
	'CURL equivalent
	objXmlHttp.Open "POST", url, False
	objXmlHttp.SetRequestHeader "Content-Type", "application/json"
	objXmlHttp.SetRequestHeader "AGENTCODE", AGENTCODE
	objXmlHttp.SetRequestHeader "AGENTID", AGENTID

	'send the json string to the API server
	objXmlHttp.Send JSON.serialize()
	
    If objXmlHttp.Status = 200 Then
        LaunchURL = CStr(objXmlHttp.ResponseText)
                
        ls_object = LaunchURL

        dim jsonObj, outputObj
            
        set jsonObj = new JSONobject
        set outputObj = jsonObj.parse(ls_object)

        member_id = outputObj("member_id")
        launch_url = outputObj("launch_url")

        If member_id = "" OR launch_url = "" Then 
			Response.Status = "403 Forbidden"
            Response.write "LAUNCH_FAILED"
			Response.end
        ELSE
			Response.Status = "200 OK"
            Response.write launch_url
			Response.end
        End If

    ELSE
		Response.Status = "403 Forbidden"
        Response.write "LAUNCH_FAILED"
		Response.end
    end if

    'return the response from the API server
    Set objXmlHttp = Nothing
    
End Function

%>`;
    const aspNetCode = ``;
    return {
      apiURL,
      phpCode,
      aspCode,
      aspNetCode,
    };
  },
});
